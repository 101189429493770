<template>
  <div class="main-box" v-cloak>
    <!-- 背景图 -->
    <div class="logo">
      <img
        src="../assets/img/banner_about us.png"
        alt=""
        style="width: 100%; height: auto"
      />
      <div class="logo-box">
        <h1>专注品牌私域流量运营服务商​</h1>
        <h3>打造营销场景、洞悉客户需求、助力服务升级</h3>
      </div>
    </div>
    <!-- 导航条 -->
    <div class="container position">
      <tabbar />
    </div>
    <!-- 关于我们 -->
    <div class="container">
      <div class="about">
        <h2>关于我们</h2>
        <div class="about-we">
          <img src="../assets/img/rawpixel-250087-unsplash.png" alt="" />
          <div>
            <p>
              <span>我们是谁：</span>
              快客云科技（武汉）有限公司，是一家基于互联网云服务技术，为品牌零售企业打造营销场景生态的新零售科技企业。公司深入“新零售、新商业”领域，助力品牌零售企业，为客户提供数字化、智能化一站式软件产品、数据产品服务及定制化IT服务解决方案。
            </p>
            <p style="text-indent: 36px">
              以基础商城构建能力、线上运营场景构建能力、客户管理系统（SCRM）、营销自动化系统（MA）、数据中台（DMP）、开发者数据开放平台（API）等服务能力为驱动，为客户提供完整的新零售解决方案，助力品牌以客户旅程为中心的运营模式转型与升级。
            </p>
            <p style="text-indent: 36px; margin-bottom: 45px">
              公司秉承“创新、奉献、责任”理念，为“新零售、新商业”
              打造行业级平台；借“数字中国”之东风，为中国互联网经济发展提供助力
            </p>
            <p>
              <span>技术团队：</span>
              团队核心均来自新浪网、珍爱网、阿里巴巴、中国移动、东创科技等知名企业，团队80%人员为技术研发工程师，主团队来自东创研发核心团队，多年研发经验的行业精英，特聘电信领域资深专家、数学建模和计算机应用领域知名教授为我司技术顾问团队专家
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 展示 -->
    <div class="show">
      <img
        src="../assets/img/bg_about us.png"
        alt=""
        style="width: 100%; hieght: auto"
      />
      <ul>
        <li>
          <p><span>15+</span>年</p>
          <p>行业领先的技术经验</p>
        </li>
        <li>
          <p><span>100+</span></p>
          <p>服务客户</p>
        </li>
        <li>
          <p><span>PB</span>级</p>
          <p>海量数据处理能力</p>
        </li>
        <li>
          <p><span>90%</span></p>
          <p>续约率</p>
        </li>
      </ul>
    </div>
    <!-- 办公地址 -->
    <div class="container">
      <div class="location">
        <h2>办公地址</h2>
        <div class="location-map">
          <el-amap
            class="amap-box"
            vid="amap-vue"
            :zoom="zoom"
            :center="center"
          >
            <el-amap-marker
              vid="marker"
              :position="center"
              :content="content"
              :offset="offset"
            >
            </el-amap-marker>
          </el-amap>
        </div>
      </div>
    </div>
    <tail />
  </div>
</template>

<script>
import Tail from "../components/common/tail.vue";
import Tabbar from "../components/common/tabbar.vue";

export default {
  data() {
    return {
      zoom: 16,
      center: [114.258397, 30.612319],
      content: `
      <div style='height:120px;border-radius: 7px;box-shadow: 0px 3px 10px 0px rgba(51,51,51,0.14);width:330px;background-color:#fff;display:flex;flex-direction: column;justify-content: space-around;align-items: center;color: #333333;'>
        <h3 style="width: 277px;height: 31px;font-size: 24px;font-weight: 400;text-align: left;">武汉</h3>
        <p style="width: 277px;height: 18px;font-size: 14px;font-weight: 300;line-height: 24px;text-align: left;">武汉市江汉区常青路武汉天街写字楼5栋2008</p>
      </div>`,
      offset: [-50, -50],
    };
  },
  components: {
    Tabbar,
    Tail,
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  position: relative;
  width: 100%;
  .logo {
    width: 100%;
    overflow: hidden;
    height: auto;
    position: relative;
    .logo-box {
      position: absolute;
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      width: 1200px;
      h1 {
        width: 100%;
        height: 84px;
        font-size: 60px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: 1px;
      }
      h3 {
        font-weight: 700;
        letter-spacing: 10px;
        width: 100%;
        height: 60px;
        font-size: 30px;
        line-height: 60px;
      }
    }
  }
  .about {
    width: 100%;
    margin-top: 94px;
    h2 {
      width: 100%;
      height: 47px;
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: #333333;
      letter-spacing: 2px;
      margin-bottom: 89px;
    }
    .about-we {
      width: 100%;
      height: 510px;
      // height: 200px;
      overflow: hidden;
      // border: 1px springgreen solid;
      margin-bottom: 37px;
      display: flex;
      justify-content: space-between;
      img {
        width: 510px;
        height: 340px;

        // background: rgba(0, 0, 0, 0);
        border-radius: 10px;
      }
      div {
        width: 600px;
        height: 510px;
        // border: 1px solid steelblue;

        p {
          width: 100%;
          height: auto;
          line-height: 30px;
          text-align: left;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1px;
          span {
            color: #006eff;
            font-weight: 700;
          }
        }
      }
    }
  }
  .show {
    width: 100%;
    height: auto;
    margin-bottom: 86px;
    position: relative;
    overflow: hidden;
    ul {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      // border: 1px solid springgreen;
      display: flex;
      justify-content: space-around;
      align-items: center;
      li {
        width: 230px;
        height: 120px;
        // background-color: tan;
        float: left;
        p {
          text-align: center;
          color: #ffffff;
          // line-height: 58px;
          font-weight: 600;
          letter-spacing: 1px;
          padding: 7px;
          span {
            font-size: 44px;
            font-weight: 700;
          }
        }
      }
    }
  }
  .location {
    width: 100%;
    height: auto;
    margin-bottom: 120px;
    h2 {
      width: 100%;
      height: 47px;
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: #333333;
      letter-spacing: 2px;
      margin-bottom: 43px;
    }
    .location-map {
      width: 100%;
      height: 498px;
      overflow: hidden;
    }
  }
}
.position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
[v-cloak] {
  display: none;
}
</style>